<template>
<div class="mt-10 h-100vh mb-10">
    <v-container v-show="show">
        <h1 class="fuchi_shadow f-26 f-22-xxs f-18-xxxs mb-5">
            <span class="pr-3">光触媒コーティング</span><br class="dis-sm">簡単お見積り
        </h1>
        <div class="home_box relative-center ">
            <v-card class="mb-6" v-for="(step,i) in steps.length" :key="i">
                <div class="float-left f-14 bold f-brown pt-1" v-show="steps.length>1">
                    【{{i+1}}】
                </div>
                <v-icon v-show="steps.length>1 && i>0" class="float-right relative t-2 r-4 f-gray" @click="deleted(i)">fas fa-times</v-icon>
                <div class="clear" />

                <div class="py-5" v-show="steps[i]==0">
                    <h2 class="mb-4"><span class="f-brown pr-1 f-20 f-16-xxxs">コーティング</span><span class="f-16 f-14-xxxs">の箇所</span></h2>
                    <v-btn color="orange  darken-2" dark x-large class="room_btn bold f-15 my-2" @click="change(i,1)">お部屋</v-btn><br>
                    <v-btn color="light-blue darken-3" dark x-large class="room_btn bold f-15 my-2" @click="change(i,4)">部屋&nbsp;以外</v-btn>
                </div>

                <div class="py-5" v-show="steps[i]==1">
                    <h2 class="mb-4"><span class="f-brown pr-1 f-20">お部屋</span><span class="f-16">の種類</span></h2>
                    <v-btn color="orange  darken-2" dark x-large class="room_btn bold f-15 f-12-xxxs my-2" @click="change(i,2) ">フローリング</v-btn><br>
                    <v-btn color="green  darken-2" dark x-large class="room_btn bold f-15 my-2" @click="change(i,3) ">和&nbsp;&nbsp;室 </v-btn>

                    <div class="text-left">
                        <v-btn text @click="change(i,0)" class="relative t-7 l-10">戻る</v-btn>
                    </div>
                </div>

                <div v-show="steps[i]==2||steps[i]==3||steps[i]==5||steps[i]==7" class="pl-1 pr-5 pt-5 pb-2">
                    <div class="mb-1 f-12 bold f-gray">
                        <span v-show="steps[i]==2">フローリング</span>
                        <span v-show="steps[i]==3">和&nbsp;&nbsp;室 </span>
                        <span v-show="steps[i]==5">廊&nbsp;&nbsp;下</span>
                        <span v-show="steps[i]==7">トイレ</span>
                    </div>
                    <h2 class="mb-6"><span class="f-brown pr-1 f-20">床面積</span><span class="f-16">の広さ</span></h2>
                    <div v-show="steps[i]==3" class=" text-center">
                        <v-row>
                            <v-col cols="6" class="f-09">
                                <div class="pt-2">
                                畳数を選択すると<br>平米数が自動入力されます
                                </div>
                            </v-col>
                            <v-col cols="6">
                                <v-select v-model="squares[i]" :items="tatamis" item-text="zyou" item-value="square" outlined label="和室の畳数" class="square ml-6" />
                            </v-col>
                        </v-row>
                        <hr class="mb-8 mx-2">
                    </div>
                    <div :class="steps[i]==3?'inline-block relative-center':' '">
                        <v-text-field v-model="squares[i]" v-show="steps[i]!=3" type="number" outlined label="床面積の広さ" class="square float-left ml-6" :max="steps[i]==7?3:999" min="0" />
                        <div class="float-left relative f-16 f-12-xxs bold pl-2" :class="steps[i]==3?'t-5 ':'t-20 '">
                            <span class="pr-2" v-show="steps[i]==3"><span class="pr-2">平米数</span><span class="f-20 f-14-xxs">{{squares[i]}}</span></span>
                            <span>m<sup>2</sup> ×</span>
                        </div>
                        <v-text-field v-model="amounts[i]" type="number" outlined label="部屋数" class="square float-left ml-6" min="0" />
                        <div class="float-left relative f-16 bold t-20 pl-2">
                            室
                        </div>
                        <div class="clear" />
                    </div>
                    <div class="text-right bold">
                        <span class="f-brown pr-2 f-14 f-12-xxs f-10-xxxs">仮見積金額</span><span class="f-orange f-26 f-22-xxs f-20-xxxs">{{prices[i].toLocaleString()}}</span><span class="f-12 f-10-xxxs">円<small class="pl-1">(税込)</small></span>
                    </div>
                    <div class="text-left">
                        <v-btn text @click="change(i,1);unset(i)" v-show="steps[i]==2||steps[i]==3">戻る</v-btn>
                        <v-btn text @click="change(i,4);unset(i)" v-show="steps[i]==5||steps[i]==7">戻る</v-btn>
                    </div>
                </div>

                <div class="py-5" v-show="steps[i]==4">
                    <h2 class="mb-4"><span class="f-brown pr-1 f-20 f-16-xxxs">コーティング</span><span class="f-16 f-14-xxxs">の箇所</span></h2>
                    <v-btn color="light-blue darken-3" dark x-large class="room_btn bold f-15 my-2" @click="change(i,5) ">廊下</v-btn><br>
                    <v-btn color="orange  darken-2" dark x-large class="room_btn bold f-15 my-2" @click="change(i,7) ">トイレ</v-btn><br>
                    <v-btn color="green  darken-2" dark x-large class="room_btn bold f-15 my-2" @click="change(i,6) ">お車</v-btn>
                    <div class="text-left">
                        <v-btn text @click="change(i,0);unset(i)" class="relative t-7 l-10">戻る</v-btn>
                    </div>
                </div>

                <div class="pt-5 pb-2" v-show="steps[i]==6">
                    <h2 class="mb-4"><span class="f-brown pr-1 f-20">お車</span><span class="f-16">の種類</span></h2>
                    <div class="select relative-center">
                        <v-select v-model="cars[i]" :items="types" item-text="name" item-value="id" outlined label="お車の種類を選択してください" />
                        <div class="mt-2 dis-xxs" v-if="cars[i] !== undefined && cars[i] !== null">
                            {{types[cars[i]].name}}
                        </div>
                    </div>

                    <hr class="mx-4 mb-10" />
                    <v-text-field v-model="squares[i]" type="number" outlined label="お車の台数" class="square float-left ml-6" min="0" />
                    <div class="float-left relative f-16 bold t-20 pl-2">
                        台
                    </div>
                    <div class="clear" />
                    <div class="text-right bold pr-5">
                        <span class="f-brown pr-2 f-14 f-12-xxs f-10-xxxs">仮見積金額</span><span class="f-orange f-26 f-22-xxs f-20-xxxs">{{prices[i].toLocaleString()}}</span><span class="f-12 f-10-xxxs">円<small class="pl-1">(税込)</small></span>
                    </div>
                    <div class="text-left">
                        <v-btn text @click="change(i,4);unset(i)" v-if="Info.type==0">戻る</v-btn>
                    </div>
                </div>

            </v-card>
            <div class="text-left">
                <v-btn color="#5D4037" dark large class="f-11" @click="plus">
                    <v-icon class="f-10 pr-1">fas fa-plus</v-icon>追加
                </v-btn>
            </div>
            <div class="text-right bold" v-show="prices.reduce((sum, element) => sum + element, 0)>0">
                <div>
                    <span class="f-24 pr-1 f-orange">{{point.toLocaleString()}}</span><span class="f-12">箇所</span>
                </div>
                <div>
                    <span class="f-12 pr-2">総平米数(車除く)</span>
                    <span class="f-24 pr-1 f-orange">{{allSquares.toLocaleString()}}</span><span class="f-12">m<sup>2</sup></span>
                </div>
                <div>
                    <span class="f-brown pr-2 f-14">仮見積金額計(税込)</span><span class="f-orange f-26">{{(prices.reduce((sum, element) => sum + element, 0)).toLocaleString()}}</span><span class="f-12">円</span>
                </div>
            </div>
            <v-btn color="#E64A19" x-large dark block class="f-12 mt-10" @click="next()" v-show="prices.reduce((sum, element) => sum + element, 0)>0">正確な見積もりをご希望の方はコチラ
</v-btn>
        </div>
    </v-container>
</div>
</template>

<script>
import form from '@/mixins/form'
import d from "@/d"
export default {
    mixins: [form],
    data() {
        return {
            show: false,

        }
    },
    computed: {
        Tatami() {
            return this.$store.getters['user/tatami']
        },
    },
    created() {
        if (!this.Info.address || (this.Info.type == 1 && this.Station.length == 0) || !this.Info.starthh) {
            window.location.href = "/notfound";
            return false;
        }//開発時コメントアウト

        if (this.Info.type == 1) {
            this.steps[0] = 6
        }

        if (this.$route.params) {
            for (let key in this.$route.params) {
                this[key] = this.$route.params[key]
            }
        }

        this.show = true
    },
    methods: {
        change(i, val) {
            let steps = this.steps
            this.steps = null
            steps[i] = val
            this.steps = steps
            if (val == 2 || val == 3) {
                let squares = this.squares
                this.squares = null
                squares[i] = 0
                this.squares = squares
            }
        },

        plus() {
            if (this.Info.type == 1) {
                this.steps.push(6)
            } else {
                this.steps.push(0)
            }
            this.squares.push(0)
            this.prices.push(0)
            this.amounts.push(0)
            this.zyous.push(0)
            this.cars.push(null)
        },

        deleted(i) {
            if (!confirm("削除しますか")) {
                return false
            }
            this.steps.splice(i, 1)
            this.squares.splice(i, 1)
            this.prices.splice(i, 1)
            this.amounts.splice(i, 1)
            this.zyous.splice(i, 1)
            this.cars.splice(i, null)
        },
        sum() {
            let prices = this.prices
            this.prices = null
            let point = []
            let allSquares = []
            let amounts = 0
            for (let i = 0; i < this.steps.length; i++) {
                let price = 0
                switch (this.steps[i]) {
                    case 3:

                        if (this.squares[i] > 0) {
                            this.zyous[i] = this.tatamis[this.tatamis.findIndex(({
                                square
                            }) => square === this.squares[i])].zyou
                            price = this.baikas[this.squares[i] - 1]

                            price = price * this.amounts[i]
                        }
                        break

                    case 2:
                    case 5:
                    case 7:

                        if (this.squares[i] > 0 && this.squares[i] < 100) {
                            price = this.baikas[this.squares[i] - 1]
                        } else if(this.squares[i] >= 100){
                            price = this.baikas[99] + (this.squares[i] - 100) * 500
                        }
                        price = price * this.amounts[i]
                        break

                    case 6:
                        if (this.cars[i] == null) {
                            break
                        }
                        price = this.squares[i] * this.types[this.cars[i]].price
                        break
                }
                if (price < 0) {
                    price = 0
                }
                prices[i] = price
                amounts = this.amounts.map(Number)

                for (let a = 0; a < prices.length; a++) {
                    if (prices[a] > 0 && this.steps[a] != 6) {
                        point[a] = amounts[a]
                        allSquares[a] = amounts[a] * this.squares[a]
                    } else if (prices[a] > 0 && this.steps[a] == 6) {
                        point[a] = Number(this.squares[a])
                    }
                }
                this.point = point.reduce(function (sum, element) {
                    return sum + element;
                }, 0);
                this.allSquares = allSquares.reduce(function (sum, element) {
                    return sum + element;
                }, 0);
                this.prices = prices
            }
        },
        next() {
            if (this.prices.reduce((sum, element) => sum + element, 0) < this.Info.minTotal) {
                alert("施工は" + this.Info.minTotal.toLocaleString() + "円以上から承っております")
                return false
            }

            let params = {
                steps: this.steps,
                squares: this.squares,
                prices: this.prices,
                cars: this.cars,
                amounts: this.amounts,
                zyous: this.zyous
            }
            params["point"] = this.point
            params["allSquares"] = this.allSquares
            params["zip"] = this.zip
            params["address"] = this.address
            params["company"] = this.company
            params["name"] = this.name
            params["email"] = this.email
            params["tel"] = this.tel
            params["contact"] = this.contact
            params["day"] = this.day ? this.day : null
            params["cDay"] = this.cDay ? this.cDay : null
            params["time"] = this.time
            params["station"] = this.station

            this.$router.push({
                name: 'estate',
                params: params,
            })
        },
        async changeSquares() {
            await d.sleep(1)
            for (let i = 0; i < this.steps.length; i++) {
                if (this.squares[i] > 999) {
                    this.$set(this.squares, i, 999)
                }
                if (this.squares[i] < 0) {
                    this.$set(this.squares, i, 0)
                }
            }
        },
        unset(i) {
            this.$set(this.amounts, i, 0)
            this.$set(this.squares, i, 0)
            this.$set(this.cars, i, null)
        }
    },

    watch: {
        squares: {
            async handler() {
                this.changeSquares()
                this.sum()
            },
            deep: true
        },
        cars: {
            async handler() {
                this.sum()
            },
            deep: true
        },
        amounts: {
            async handler() {
                this.sum()
            },
            deep: true
        },
        zyous: {
            async handler() {
                this.sum()
            },
            deep: true
        },
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/home.scss";
</style>
